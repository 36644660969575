import axios from 'axios';

axios.defaults.baseURL = 'https://engine.cinch.io'; // TODO FIX ME FOR DIFFERENT ENVIRONMENTS

export const getSurvey = (invite_id) => {
  return axios.get(`/surveys/invites/${invite_id}/start`);
}

export const saveAnswer = (invite_id, question_id, answer) => {
  return axios.post(`/surveys/invites/${invite_id}/answer`, {
    question_id, answer
  });
}

export const updateAnswer = (invite_id, answer_id, answer) => {
  return axios.post(`/surveys/invites/${invite_id}/answer/${answer_id}`, {
    answer
  });
}
